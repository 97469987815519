export const getAppVersion = async (): Promise<string> => {
  const response = await fetch('/version.json', {
    method: 'get',
    headers: {
      pragma: 'no-cache',
      'cache-control': 'no-cache',
    },
  })

  return response.status === 200 ? (await response.json()).RELEASE_VERSION : await Promise.reject(response)
}
