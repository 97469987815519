import { createBrowserRouter } from 'react-router-dom'

import AppRoutes from './AppRoutes'

/**
 * Create the application router
 * If this is imported directly into a component or anything called
 * in a component it will cause a ciruclar dependency
 */
export const router = createBrowserRouter([
  // match everything with "*"
  { path: '*', element: <AppRoutes /> },
])
