class GeolocationTimeoutException extends Error {
  constructor () {
    super('Timeout occured retrieving location')

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, GeolocationTimeoutException)
    }

    this.name = 'GeolocationTimeoutException'
  }
}

export default GeolocationTimeoutException
