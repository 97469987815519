import {
  Route,
} from 'react-router-dom'

import AuthPage from '~/components/Page/AuthPage'
import LandingView from '~/views/Landing/LandingView'
import SignInView from '~/views/Auth/SignIn/SignInView'
import SignUpView from '~/views/Auth/SignUp/SignUpView'
import ConfirmSignUpView from '~/views/Auth/ConfirmSignUp/ConfirmSignUpView'
import ForgotPasswordView from '~/views/Auth/ForgotPassword/ForgotPasswordView'

import { ROUTE_CONFIRM_SIGN_UP, ROUTE_FORGOT_PASSWORD, ROUTE_LANDING, ROUTE_LOGIN, ROUTE_SIGN_UP } from './Routes'

const AuthRoutes = (
  <>
    <Route path={ROUTE_LANDING} element={<LandingView />} />
    <Route element={<AuthPage />}>
      <Route path={ROUTE_LOGIN} element={<SignInView />} />
      <Route path={ROUTE_SIGN_UP} element={<SignUpView />} />
      <Route path={ROUTE_CONFIRM_SIGN_UP} element={<ConfirmSignUpView />} />
      <Route path={ROUTE_FORGOT_PASSWORD} element={<ForgotPasswordView />} />
    </Route>
  </>
)

export default AuthRoutes
